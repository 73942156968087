import { ActionIcon, createStyles, Loader, Stack, Text } from '@mantine/core';
import { find } from 'lodash/fp';
import React from 'react';

import { WIDGET_ICONS } from '../../widgets.constants';
import { useDebouncedTelemetryValue } from '../../widgets.hooks';
import { WidgetColorType } from '../../widgets.types';
import { useGroupedSwitchesWidgetContext } from './grouped-switches-widget.context';

export interface GroupedSwitchProps {
  key: string;
  iconName: string;
  commandName: string;
  commandParamKey: string;
  telemetry: string;
  label: string;
  color: WidgetColorType;
  displayTitle: boolean;
}

export function GroupedSwitch({
  commandName,
  commandParamKey,
  iconName,
  label,
  telemetry,
  color,
  displayTitle,
}: GroupedSwitchProps) {
  const { onToggle, deviceState, activeCommands } =
    useGroupedSwitchesWidgetContext();

  const { classes } = useStyles();
  const widgetIcon = WIDGET_ICONS.find(
    ({ iconName: name }) => name === iconName
  );

  const Icon = widgetIcon?.Icon || WIDGET_ICONS[0].Icon;

  const telemetryValue = deviceState?.[telemetry];
  const activeCommand = find({ name: commandName }, activeCommands);
  const isCommandActive = Boolean(activeCommand);

  const sentCommandValue =
    activeCommand && activeCommand.params?.[commandParamKey];

  const debouncedTelemetryValue = useDebouncedTelemetryValue(
    telemetryValue,
    sentCommandValue
  );

  return (
    <ActionIcon
      w={86}
      h={86}
      className={classes.container}
      color={debouncedTelemetryValue ? color : 'gray.7'}
      variant={debouncedTelemetryValue ? 'filled' : 'light'}
      onClick={() => onToggle(commandName, commandParamKey, !telemetryValue)}
      disabled={isCommandActive}
      bg={debouncedTelemetryValue ? color : 'gray.1'}
    >
      <Stack justify="center" align="center" spacing="xs">
        {isCommandActive ? <Loader size="sm" color={color} /> : <Icon />}

        {displayTitle ? (
          <Text
            size="xs"
            data-testid="dashboard-grouped-switched-title"
            truncate
            align="center"
          >
            {label}
          </Text>
        ) : null}
      </Stack>
    </ActionIcon>
  );
}

const useStyles = createStyles((theme) => ({
  container: {
    borderRadius: theme.radius.md,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
}));
