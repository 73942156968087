import { ActionIcon, Box, createStyles } from '@mantine/core';
import { get } from 'lodash/fp';
import React, { FC } from 'react';

import { ReactComponent as ArrowRight1 } from '@portals/icons/linear/arrow-right-1.svg';
import { useIsSidebarOpen } from '@portals/redux';
import { DesignType } from '@portals/types';

import {
  useCommonConfig,
  useCommonDesign,
  useCommonOrganizationConfig,
} from '../../../../hooks/portal-config';
import { UserAvatar, UserName } from './common';

interface TenantUserProps {
  isActive: boolean;
  isDisabled?: boolean;
  logoSrc: string;
  label: string;
  isLab?: boolean;
  partner?: string;
}

const TenantUser: FC<TenantUserProps> = ({
  isActive,
  logoSrc,
  label,
  isLab = false,
  partner,
  isDisabled,
}) => {
  const isSidebarOpen = useIsSidebarOpen();
  const design = useCommonDesign();
  const { classes, cx } = useStyles({ design });

  return (
    <Box px="md" className={cx(classes.container, { hoverable: !isDisabled })}>
      <UserAvatar logoSrc={logoSrc} isActive={isActive} />

      {isSidebarOpen ? (
        <>
          <UserName name={label} isLab={isLab} partner={partner} />

          {isDisabled ? null : (
            <ActionIcon
              className={cx(classes.toggle, 'tenant-menu-toggle', {
                active: isActive,
              })}
            >
              <ArrowRight1 />
            </ActionIcon>
          )}
        </>
      ) : null}
    </Box>
  );
};

const useStyles = createStyles((theme, { design }: { design: DesignType }) => ({
  container: {
    height: '100%',
    display: 'grid',
    alignItems: 'center',
    gridTemplateColumns: '44px 1fr 18px',
    gridColumnGap: theme.spacing.md,

    '&.hoverable': {
      '&:hover': {
        '.tenant-menu-avatar': {
          borderColor:
            design?.navigation_menu?.selected_section_background_color ||
            theme.colors.blue_accent[4],
        },

        '.tenant-user-menu': {
          color:
            design?.navigation_menu?.hovered_section_text_color ||
            theme.colors.blue_accent[4],
        },

        '.tenant-menu-toggle': {
          backgroundColor:
            design?.navigation_menu?.hovered_section_background_color ||
            theme.colors.gray[1],
        },
      },
    },
  },
  toggle: {
    transition: 'color 0.1s ease-in-out',
    color: theme.colors.blue_gray[2],

    svg: {
      width: 18,
      height: 18,
    },

    '&.active': {
      color:
        design?.navigation_menu?.selected_section_background_color ||
        theme.colors.blue_accent[4],
    },
  },
}));

export const OrganizationUser: FC<
  Pick<TenantUserProps, 'isActive' | 'isDisabled'>
> = ({ isActive, isDisabled }) => {
  const organization = useCommonOrganizationConfig();
  const design = useCommonDesign();

  if (!organization) return null;

  return (
    <TenantUser
      isDisabled={isDisabled}
      isActive={isActive}
      logoSrc={design?.navigation_menu?.logo}
      label={organization?.name}
      isLab={organization?.lab}
      partner={organization?.partner_display_name}
    />
  );
};

export const PartnerUser: FC<
  Pick<TenantUserProps, 'isActive' | 'isDisabled'>
> = ({ isActive, isDisabled }) => {
  const config = useCommonConfig();

  if (!config || !config.isFetched) return null;

  return (
    <TenantUser
      isDisabled={isDisabled}
      isActive={isActive}
      logoSrc={get(['partner', 'logo_url'], config?.data)}
      label={get(['partner', 'display_name'], config?.data)}
    />
  );
};
