import { AuthType, TenantType } from '@portals/types';

import {
  API,
  SIGN_IN_FAILED,
  SIGNED_IN,
  SIGNED_OUT,
  SWITCH_TENANT,
  UPDATE_AUTH_TENANT_NAME,
} from '../constants';
import { setRoute } from './routing';

export const signedIn = (payload) => ({
  type: SIGNED_IN,
  payload,
});

export const signInFail = (error) => ({
  type: SIGN_IN_FAILED,
  payload: error,
});

export const signIn = (email, password, tenantType, tenantId) => ({
  type: API,
  payload: {
    url: 'auth/sign_in',
    data: {
      email,
      password,
      [tenantType]: true,
      last_tenant_id: tenantId,
      accepted_terms_and_conditions: true,
    },
    success: signedIn,
    error: signInFail,
    name: 'signIn',
  },
  meta: { log: false },
});

export const signInWithGoogle = (
  token: string,
  tenantId: string,
  tenantType: TenantType
) => ({
  type: API,
  payload: {
    url: 'auth/google/login',
    data: {
      token,
      last_tenant_id: tenantId,
      [tenantType]: true,
      accepted_terms_and_conditions: true,
    },
    success: signedIn,
    error: signInFail,
    name: 'signIn',
  },
  meta: { log: false },
});

export const signedOut = () => ({
  type: SIGNED_OUT,
});

export const signOut = () => ({
  type: API,
  payload: {
    url: 'auth/sign_out',
    method: 'delete',
    success: signedOut,
    error: signedOut,
  },
});

export const updateAuthTenantName = (tenantName: string) => ({
  type: UPDATE_AUTH_TENANT_NAME,
  payload: { tenantName },
});

export const switchTenant = (tenant, type, navigateToRoot = false) => ({
  type: SWITCH_TENANT,
  payload: { ...tenant, type },
  meta: {
    navigateToRoot,
  },
});

export const getAuthHeaders = (auth: AuthType | undefined) => {
  return new Headers({
    client: auth?.client ?? '',
    'access-token': auth?.token ?? '',
    uid: auth?.uid ?? '',
    'token-type': 'Bearer',
    expiry: auth?.expiry ?? '',
    tenant: auth?.tenant?.id ?? '',
    'tenant-type': auth?.tenant?.type ?? '',
  });
};

export const createOrg = (
  org: string,
  name: string,
  email: string,
  password: string,
  terms: boolean,
  referral: string | undefined,
  afterAuthPath?: string
) => ({
  type: API,
  payload: {
    url: 'ui/organization/organizations',
    data: {
      org,
      name,
      email,
      password,
      referral,
      accepted_terms_and_conditions: terms,
    },
    success: [signedIn, setRoute(afterAuthPath || '/')],
    withError: true,
    name: 'createOrg',
  },
  meta: { log: false },
});

export const forgotPassword = (email, tenant) => ({
  type: API,
  payload: {
    url: 'ui/users/forgot_password',
    data: { email, tenant },
    success: setRoute('/auth/sign-in'),
    withError: true,
    name: 'resetPasswordLink',
    toastr: 'Password reset link sent in email',
  },
});

export const changePasswordToken = (token, password, tenant) => ({
  type: API,
  payload: {
    url: 'ui/users/reset_password_token',
    data: { token, password, tenant },
    success: [signedIn, setRoute('/')],
    withError: true,
    name: 'changePasswordToken',
    toastr: 'New password set',
  },
  meta: { log: false },
});

export const changePassword = (data, extraActions) => ({
  type: API,
  payload: {
    url: 'ui/users/change_password',
    data,
    success: [signedIn, extraActions],
    withError: true,
    name: 'changePassword',
    toastr: 'New password set',
  },
  meta: { log: false },
});
