import { noop } from 'lodash/fp';
import React from 'react';
import { useSelector } from 'react-redux';

import { SignUp } from '@portals/framework';
import { getErrorByActionName } from '@portals/redux';

import Notification from '../../components/layout/Notification';
import { AuthWrapper } from './common';

export function SignUpPage() {
  const createOrgError = useSelector(getErrorByActionName('createOrg'));

  return (
    <AuthWrapper>
      <SignUp />

      <Notification error={createOrgError} onClear={noop} />
    </AuthWrapper>
  );
}
