import {
  Box,
  createStyles,
  Group,
  ScrollArea,
  Tabs,
  TabsProps,
} from '@mantine/core';
import { find } from 'lodash/fp';
import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';

import {
  DeviceModelType as OrgDeviceModelType,
  DeviceType,
} from '@portals/api/organizations';
import { DeviceModelType as PartnerDeviceModelType } from '@portals/api/partners';
import { VerticalScrollBar } from '@portals/scrollbar';
import { DeviceType as CommonDeviceType, TabType } from '@portals/types';

import { useRouteModalConfig } from '../../route-modals.hooks';
import { useDeviceContext } from './context';

interface DeviceTabsProps<TDevice, TDeviceModel> {
  device: TDevice;
  model: TDeviceModel;
}

export function DeviceTabs<
  TDevice extends DeviceType | CommonDeviceType,
  TDeviceModel extends OrgDeviceModelType | PartnerDeviceModelType
>({ device, model }: DeviceTabsProps<TDevice, TDeviceModel>) {
  const { classes } = useStyles();
  const history = useHistory();
  const params = useParams<{ tab_id?: string }>();
  const routeModalConfig = useRouteModalConfig('device');
  const deviceContext = useDeviceContext();

  const [activeTabId, setActiveTabId] = useState(
    params.tab_id || deviceContext.tabs[0].id
  );

  useEffect(
    function updateRouteWithInitialTabId() {
      if (!params.tab_id) {
        history.replace(
          routeModalConfig.generatePath([device.id, activeTabId]),
          history.location.state
        );
      }

      const tab = find({ id: params.tab_id }, deviceContext.tabs);
      if (!tab) {
        history.replace(
          routeModalConfig.generatePath([device.id, deviceContext.tabs[0].id]),
          history.location.state
        );
      }
    },
    [
      activeTabId,
      device.id,
      history,
      params.tab_id,
      routeModalConfig,
      deviceContext.tabs,
    ]
  );

  useEffect(
    function handleNativeRoutingActions() {
      // update active tabId when actions like 'back' & 'forward' occur
      if (params.tab_id && params.tab_id !== activeTabId) {
        setActiveTabId(params.tab_id);
      }
    },
    [activeTabId, params.tab_id]
  );

  const onTabChange = (value: string) => {
    if (value === activeTabId) return;

    setActiveTabId(value);
    history.push(
      routeModalConfig.generatePath([device.id, value]),
      history.location.state
    );
  };

  const tabLabelRenderer = ({
    title,
    Counter,
    counterProps,
  }: Pick<TabType, 'title' | 'Counter' | 'counterProps'>) =>
    Counter ? (
      <Group spacing="xs" noWrap>
        {title}
        <Counter {...counterProps?.(device)} />
      </Group>
    ) : (
      title
    );

  return (
    <div className={classes.container}>
      <Tabs
        keepMounted={false}
        styles={tabsStyles}
        value={activeTabId}
        onTabChange={onTabChange}
      >
        <ScrollArea type="auto">
          <Tabs.List grow>
            {(deviceContext.tabs || []).map(
              ({ id, title, Counter, counterProps }) => (
                <Tabs.Tab key={id} value={id} data-testid={`device-tab-${id}`}>
                  {tabLabelRenderer({ title, Counter, counterProps })}
                </Tabs.Tab>
              )
            )}
          </Tabs.List>
        </ScrollArea>

        {(deviceContext.tabs || []).map(({ id, Component }) => (
          <Tabs.Panel key={id} value={id}>
            <VerticalScrollBar renderView={(props) => <Box {...props} />}>
              <Component device={device} model={model} />
            </VerticalScrollBar>
          </Tabs.Panel>
        ))}
      </Tabs>
    </div>
  );
}

const useStyles = createStyles((theme) => ({
  container: {
    height: '100%',
    width: '100%',
    background: theme.colors.gray[0],
    padding: 30,
    paddingBottom: 0,
  },
}));

const tabsStyles: TabsProps['styles'] = (theme) => ({
  root: {
    height: '100%',
    display: 'grid',
    gridTemplateRows: 'max-content 1fr',
  },
  tabsList: {
    paddingRight: theme.spacing.xl,
    flexWrap: 'nowrap',
  },
  tab: {
    height: 37,

    [`${theme.fn.smallerThan(1550)}`]: {
      padding: theme.spacing.xs,
    },
  },
});
