import { createStyles, Paper, Stack, Text } from '@mantine/core';
import React from 'react';
import { Link } from 'react-router-dom';

import { SettingPage } from './settings-page.types';

interface SettingCardProps {
  icon: SettingPage['icon'];
  title: SettingPage['title'];
  description: SettingPage['description'];
  path: SettingPage['path'];
  ribbon?: SettingPage['ribbon'];
  badge?: SettingPage['badge'];
}

export function SettingCard({
  icon,
  title,
  description,
  path,
  ribbon,
  badge,
}: SettingCardProps) {
  const { classes } = useStyles();

  return (
    <Paper
      component={Link}
      to={path}
      withBorder
      radius="md"
      className={classes.container}
    >
      {badge && <div className={classes.badge}>{badge}</div>}

      <>{ribbon}</>

      <Stack spacing="xs" className={classes.content}>
        <div className={classes.iconContainer}>{icon}</div>

        <Text
          size="lg"
          color="gray.9"
          weight={600}
          data-testid={`settings-card-title-${path}`}
        >
          {title}
        </Text>

        <Text color="gray.6">{description}</Text>
      </Stack>
    </Paper>
  );
}

const useStyles = createStyles((theme) => ({
  container: {
    position: 'relative',
    width: 370,
    height: 225,
    paddingTop: 40,
    paddingInline: theme.spacing.xl,
    paddingBottom: theme.spacing.xl,
    transition: 'all 150ms linear',

    '&:hover': {
      boxShadow: theme.shadows.lg,
      textDecoration: 'none',
    },
  },
  badge: {
    position: 'absolute',
    top: theme.spacing.xl,
    left: theme.spacing.xl,

    color: theme.colors.blue[3],
  },
  content: {
    height: '100%',
    alignItems: 'center',
    textAlign: 'center',
  },
  iconContainer: {
    color: theme.colors.blue[3],

    svg: {
      width: 32,
      height: 32,
    },
  },
}));
