import { LoadingOverlay } from '@mantine/core';
import React, { FC, ReactNode } from 'react';

import { useAppConfig } from '../context';
import { useCommonPortalConfig } from '../hooks/portal-config';

type ShopProps = {
  children: ReactNode;
  isPreview?: boolean;
  className?: string;
};

export const Shop: FC<ShopProps> = ({ children, className = '' }) => {
  const { extraLayout } = useAppConfig();
  const portalConfig = useCommonPortalConfig();

  if (!portalConfig.isFetched) return <LoadingOverlay visible />;

  return (
    <>
      {children}

      {extraLayout?.globalLayout ? extraLayout.globalLayout() : null}
    </>
  );
};

export default Shop;
