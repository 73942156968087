import {
  Box,
  Button,
  createStyles,
  Flex,
  Group,
  LoadingOverlay,
  Modal,
  Stack,
  Text,
} from '@mantine/core';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import Cropper from 'react-cropper';
import { ImageType } from 'react-images-uploading';
import 'rc-slider/assets/index.css';

import { useUploadFileToS3 } from '@portals/api';
import { ImageInput } from '@portals/autoformik';

import { ModalProps } from '../../components/Modals';
import ImageCropperActions from './ImageCropperActions';

export interface UploadImageModalProps
  extends ModalProps<{
    title?: string;
    onUpload?: (imageUrl: string) => void;
    onError?: (error: string) => void;
    image?: ImageType;
    cropConfig: {
      aspectRatio: number;
      width: number;
      height: number;
    };
  }> {}

export function UploadImageModal({ closeMe, data }: UploadImageModalProps) {
  const { classes } = useStyles();
  const { mutateAsync: uploadFile, isLoading } = useUploadFileToS3();

  const cropperRef = useRef<Cropper>();
  const { title = 'Upload image', cropConfig, image, onUpload, onError } = data;

  const [imageToCrop, setImageToCrop] = useState<ImageType>(image);
  const [crop, setCrop] = useState({ straighten: 0, scale: 1 });

  const onInitialized = useCallback((ref: Cropper) => {
    cropperRef.current = ref;
  }, []);

  const onReady = useCallback(() => {
    cropperRef.current.scale(crop.scale);
    cropperRef.current.rotateTo(crop.straighten);
  }, [crop.straighten, crop.scale]);

  const onConfirm = useCallback(async () => {
    if (!imageToCrop) return;

    const canvas = cropperRef.current.getCroppedCanvas({
      width: cropConfig.width,
      height: cropConfig.height,
    });

    try {
      canvas.toBlob(async (blob) => {
        const fileUrl = await uploadFile({
          blob,
          originalFileName: imageToCrop?.file.name,
        });

        if (onUpload) {
          onUpload(fileUrl);
        }

        closeMe();
      }, imageToCrop?.file.type);
    } catch (err) {
      onError?.(err);
    }
  }, [closeMe, cropConfig, imageToCrop, onError, onUpload, uploadFile]);

  const onSelectImage = (images: Array<ImageType>) => setImageToCrop(images[0]);

  useEffect(
    function updateScale() {
      if (cropperRef.current) {
        cropperRef.current.scale(crop.scale);
      }
    },
    [crop.scale]
  );

  useEffect(
    function updateStraighten() {
      if (cropperRef.current) {
        cropperRef.current.rotateTo(crop.straighten);
      }
    },
    [crop.straighten]
  );

  return (
    <Modal opened onClose={closeMe} title={title}>
      <Stack pos="relative">
        <LoadingOverlay visible={isLoading} />

        {cropConfig.width && cropConfig.height && !imageToCrop ? (
          <Text size="xs" color="blue_gray.3">
            Recommended size: {cropConfig.width}x{cropConfig.height}px
          </Text>
        ) : null}

        <Stack spacing={0}>
          {imageToCrop ? (
            <>
              <Flex w="100%" align="center" justify="center" bg="gray.4">
                <Cropper
                  src={imageToCrop.dataURL}
                  guides
                  center
                  dragMode="move"
                  cropBoxMovable={false}
                  cropBoxResizable={false}
                  zoomOnWheel={false}
                  className={classes.cropper}
                  aspectRatio={cropConfig.aspectRatio}
                  minCropBoxHeight={250}
                  onInitialized={onInitialized}
                  ready={onReady}
                />
              </Flex>

              <ImageCropperActions crop={crop} setCrop={setCrop} />
            </>
          ) : (
            <Box w="100%" h={300}>
              <ImageInput
                onChange={onSelectImage}
                label={title}
                cropConfig={cropConfig}
              />
            </Box>
          )}

          <Group position="right" pt="xl">
            <Button variant="default" onClick={closeMe} disabled={isLoading}>
              Cancel
            </Button>

            <Button onClick={onConfirm} disabled={!imageToCrop || isLoading}>
              Save
            </Button>
          </Group>
        </Stack>
      </Stack>
    </Modal>
  );
}

const useStyles = createStyles(() => ({
  cropper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    height: 300,
  },
}));
