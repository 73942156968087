import { createStyles, Group } from '@mantine/core';
import React from 'react';
import { Link, useParams } from 'react-router-dom';

import { ReactComponent as ArrowLeft } from '@portals/icons/linear/arrow-left.svg';

import { DashboardContentLayout } from '../../components/layout/DashboardContentLayout';
import { Page404 } from '../auth/Page404';
import {
  SETTING_PAGE_ID_URL_PARAM_NAME,
  SettingPage,
} from './settings-page.types';
import { SettingsPageGrid } from './SettingsPageGrid';

export interface SettingsPageProps {
  basePath: string;
  rootPageTitle: string;
  pages: SettingPage[];
}

export function SettingsPage({
  rootPageTitle,
  basePath,
  pages,
}: SettingsPageProps) {
  const { classes } = useStyles();
  const settingPageId = useGetSettingPageIdFromUrlParams();

  const selectedSettingItem = pages.find(
    (page) => page.path === `/${settingPageId}`
  );

  const pageTitle = selectedSettingItem ? (
    <Link to={basePath} className={classes.backLink}>
      <Group display="inline-flex">
        <ArrowLeft />
        <span>{selectedSettingItem.title}</span>
      </Group>
    </Link>
  ) : (
    rootPageTitle
  );

  if (
    selectedSettingItem &&
    (selectedSettingItem.visible === false ||
      selectedSettingItem.isRouteBlocked)
  ) {
    return <Page404 />;
  }

  return (
    <DashboardContentLayout
      pageTitle={pageTitle}
      bg="gray.0"
      key={settingPageId || 'settings'}
    >
      {selectedSettingItem ? (
        <>{selectedSettingItem.component}</>
      ) : (
        <SettingsPageGrid basePath={basePath} pages={pages} />
      )}
    </DashboardContentLayout>
  );
}

const useStyles = createStyles((theme) => ({
  backLink: {
    color: theme.colors.blue_gray[9],

    '&:hover': {
      color: theme.colors.blue_gray[9],
      textDecoration: 'none',
    },
  },
}));

export function useGetSettingPageIdFromUrlParams() {
  const { settingPageId } =
    useParams<{ [SETTING_PAGE_ID_URL_PARAM_NAME]: string | undefined }>();

  return settingPageId;
}
