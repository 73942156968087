import GoogleMapReact from 'google-map-react';
import { ComponentType, ReactNode } from 'react';
import { Layout, Layouts } from 'react-grid-layout';

import { FieldType } from './autoformik';
import {
  CommonFeatureFlagsType,
  CommonPortalCapabilities,
  CommonPricingPlanFeatures,
} from './configuration';
import { AccessLevelEnum, UserPermissionsCallback } from './permissions';
import { StateType } from './redux';

export type ComponentRendererType<TProps extends object = Record<string, any>> =
  ComponentType<TProps>;

export type CanAccessRouteFn<
  TPortalCapabilities extends CommonPortalCapabilities,
  TFeatureFlags extends CommonFeatureFlagsType,
  TPricingPlanFeatures extends CommonPricingPlanFeatures
> = (params: {
  canView: UserPermissionsCallback;
  canEdit: UserPermissionsCallback;
  featureFlags: TFeatureFlags;
  portalCapabilities: TPortalCapabilities;
  pricingPlanFeatures: TPricingPlanFeatures;
  isAdmin: boolean;
  hasSupportSeat: boolean;
  classification: string | undefined;
}) => boolean;

export type TabVisibleFn<
  ExtraParams extends object,
  TFeatureFlags extends CommonFeatureFlagsType = CommonFeatureFlagsType,
  TPortalCapabilities extends CommonPortalCapabilities = CommonPortalCapabilities
> = (
  params: {
    featureFlags: TFeatureFlags;
    portalCapabilities: TPortalCapabilities;
    canView: UserPermissionsCallback;
    canEdit: UserPermissionsCallback;
    isAdmin: boolean;
  } & ExtraParams
) => boolean;

export interface RoutesMap<
  TFeatureFlags extends CommonFeatureFlagsType,
  TPortalCapabilities extends CommonPortalCapabilities,
  TPricingPlanFeatures extends CommonPricingPlanFeatures
> {
  dashboard: CategoryRouteItem<
    TPortalCapabilities,
    TFeatureFlags,
    TPricingPlanFeatures
  >[];
  auth?: RoutesType<TPortalCapabilities, TFeatureFlags, TPricingPlanFeatures>;
  shop?: RoutesType<TPortalCapabilities, TFeatureFlags, TPricingPlanFeatures>;
  onboarding?: RoutesType<
    TPortalCapabilities,
    TFeatureFlags,
    TPricingPlanFeatures
  >;
  routeModals?: EntityRouteType<
    TPortalCapabilities,
    TFeatureFlags,
    TPricingPlanFeatures
  >[];
  routePanels?: EntityRouteType<
    TPortalCapabilities,
    TFeatureFlags,
    TPricingPlanFeatures
  >[];
}

export interface CategoryRouteItem<
  TPortalCapabilities extends CommonPortalCapabilities,
  TFeatureFlags extends CommonFeatureFlagsType,
  TPricingPlanFeatures extends CommonPricingPlanFeatures
> {
  id: string;
  headerId: string;
  childRoutes: RoutesType<
    TPortalCapabilities,
    TFeatureFlags,
    TPricingPlanFeatures
  >;
  canAccessRoute?: CanAccessRouteFn<
    TPortalCapabilities,
    TFeatureFlags,
    TPricingPlanFeatures
  >;
}

export type RouteType<
  TPortalCapabilities extends CommonPortalCapabilities,
  TFeatureFlags extends CommonFeatureFlagsType,
  TPricingPlanFeatures extends CommonPricingPlanFeatures
> = {
  headerId?: string;
  href?: string;
  path:
    | ((params: {
        featureFlags: TFeatureFlags;
        portalCapabilities: TPortalCapabilities;
        pricingPlanFeatures: TPricingPlanFeatures;
        isAdmin: boolean;
      }) => Array<string>)
    | Array<string>
    | string;
  id?: string;
  component?: ComponentRendererType;
  icon?: ComponentRendererType;
  badgeComponent?: ComponentRendererType;
  indicatorComponent?: ComponentRendererType;
  badgeColor?: string;
  badgeText?: string;
  isVisibleInSidebar?: (params: {
    featureFlags: TFeatureFlags;
    portalCapabilities: TPortalCapabilities;
    classification: string | undefined;
  }) => boolean;
  canAccessRoute?: CanAccessRouteFn<
    TPortalCapabilities,
    TFeatureFlags,
    TPricingPlanFeatures
  >;
  children?: Array<
    RouteType<TPortalCapabilities, TFeatureFlags, TPricingPlanFeatures>
  >;
  open?: boolean;
  containsHome?: boolean;
  hasBorder?: boolean;
};

export interface EntityRouteType<
  TPortalCapabilities extends CommonPortalCapabilities,
  TFeatureFlags extends CommonFeatureFlagsType,
  TPricingPlanFeatures extends CommonPricingPlanFeatures
> extends RouteType<TPortalCapabilities, TFeatureFlags, TPricingPlanFeatures> {
  path: string | Array<string>;
  backgroundPath: string;
  generatePath: (params: Array<string>) => string;
}

export enum TenantType {
  Organization = 'organization',
  Partner = 'partner',
}

export type CustomColorName = `custom_color_${string}`;

export interface CustomColor {
  colorHex: string;
}

export interface DesignType {
  sign_in_page?: {
    logo: string | null;
    login_image: string | null;
    tagline: string | null;
    powered_by_xyte_label: boolean | null;
    tagline_color: string | null;
    background_color: string | null;
    background_image: string | null;
    background_mode: 'image' | 'color';
    primary_color: string | null;
    text_color: string | null;
  };
  navigation_menu?: {
    logo: string | null;
    menu_background_color: string | null;
    company_text_color: string | null;
    menu_text_color: string | null;
    selected_section_background_color: string | null;
    selected_section_text_color: string | null;
    hovered_section_background_color: string | null;
    hovered_section_text_color: string | null;
  };
  custom_colors?: string[];
}

export type RoutesType<
  TPortalCapabilities extends CommonPortalCapabilities,
  TFeatureFlags extends CommonFeatureFlagsType,
  TPricingPlanFeatures extends CommonPricingPlanFeatures
> = Array<RouteType<TPortalCapabilities, TFeatureFlags, TPricingPlanFeatures>>;
export type ModalsType = Record<string, any>;

export type SidebarLayoutCallback<
  TFeatureFlags extends CommonFeatureFlagsType = CommonFeatureFlagsType,
  TPortalCapabilities extends CommonPortalCapabilities = CommonPortalCapabilities
> = (params: {
  featureFlags: TFeatureFlags;
  portalCapabilities: TPortalCapabilities;
}) => JSX.Element;

export type ExtraLayoutType<TFeatureFlags extends CommonFeatureFlagsType> = {
  dashboardLayout?: any;
  shopLayout?: any;
  authLayout?: any;
  onboardingLayout?: any;
  globalLayout?: () => JSX.Element;
  navbarLayout?: () => JSX.Element;
  sidebarLayout?: SidebarLayoutCallback<TFeatureFlags>;
  dashboardDataLayout?: ComponentType<{
    children: ReactNode;
  }>;
  options?: {
    isMobile?: boolean;
  };
};

export type AppProps<
  TFeatureFlags extends CommonFeatureFlagsType,
  TPortalCapabilities extends CommonPortalCapabilities,
  TPricingPlanFeatures extends CommonPricingPlanFeatures
> = {
  readonly routes: RoutesMap<
    TFeatureFlags,
    TPortalCapabilities,
    TPricingPlanFeatures
  >;
  readonly modals: ModalsType;
  readonly tenantType: TenantType;
  readonly extraLayout?: ExtraLayoutType<TFeatureFlags>;
};

export type TelemetryType = {
  id: string;
  created_at: string;
  timestamp: string;
  device_timestamp: string;
  common: JSON;
};

export type IncidentPriorityLevel = 1 | 2 | 3 | 4 | 5;

export type OrganizationIncidentType = {
  device_id: string;
  status: string;
  id: string;
  priority: IncidentPriorityLevel;
  title: string;
  description: string;
  issue: string;
  created_at: string;
  closed_at: string;
  ttc: string;
  space_id: string;
  urls: Record<string, string>;
  updated_at: string;
  access_level: AccessLevelEnum;
  device_model: string;
  device_name: string;
  org_display_name: string;
  partner_display_name: string;
  space_name: string;
};

export type AccessType = Record<string, Record<UuidType, AccessLevelType>>;

export interface SpaceStateIncidentsType {
  low: number;
  high: number;
  total: number;
  critical: number;
  moderate: number;
  planning: number;
}

export type DeviceStatusType = 'online' | 'offline' | 'unavailable' | 'error';

// Partner-only, should be moved to @portals/api/partners when devices are moved to RQ from
// redux in Partners portal
export type DeviceType = {
  parent: null | string;
  id: string;
  name: string;
  space_id: number;
  state: {
    status?: DeviceStatusType;
  };
  snoozed_until?: string;
  config_version: number;
  status: DeviceStatusType;
  type: string;
  model_settings: {
    icon?: DeviceModelIconName;
    media?: {
      img?: string;
    };
    commands: Array<SupportedCommandType>;
    visibility: {
      claimable: boolean;
    };
  };
  partner: {
    sn: null | any;
    mac: null | any;
    vendor: string;
    model: string;
    type_id: string;
    cloud_id?: string;
    sub_model?: string;
  };
  details: null | any;
  firmware: string;
  config: {
    other: {
      sample: number;
    };
    network: {
      ip: string;
      port: number;
    };
    version: number;
    last_updated: string;
  };
  organization?: {
    id: string;
    name: string;
    logo_url: string | null;
  };
  config_schema: null | any;
  is_config_schema_readonly: boolean;
  driver: null | any;
  incidents: Array<number>;
  last_seen: string;
  access_key: string;
  custom_info: Record<string, any>;
  custom_info_version: number;
  access_level: AccessLevelEnum;
  claimed?: boolean;
  device_model_id?: UuidType;
  device_model_active?: boolean;
  model?: string;
  created_at?: string;
  partner_note?: string;
  note?: string;
  sn?: string;
  mac?: string;
  cloud_id?: string;
  connection_info: {
    access_key?: string;
    hub_url?: string;
    hub_url_static_cert?: string;
    mqtt_hub_url?: string;
  };
  registration_failed_at: string | null;
  awaiting_replacement: boolean;
  space_tree_path_name: string;

  tabs: {
    commands: boolean;
    configuration: boolean;
    dumps: boolean;
    files: boolean;
    licenses: boolean;
    state: boolean;
    telemetries: boolean;
  };
};

export type IntegrationExtraType = {
  item_fields: Array<{ name: string; label: string; choices: Array<string> }>;
  required_fields: Array<{
    name: string;
    label: string;
    choices: Array<string>;
  }>;
} & Record<string, unknown>;

interface ZoomRoomType {
  id: string;
  location_id: string;
  name: string;
  room_id: string;
  status: string;
}

export interface IntegrationsExtraZoomType {
  name: 'zoom';
  state: string;
  updated_at: string;
  import: {
    devices: number;
    spaces: number;
    state: string;
  };
  rooms: Array<ZoomRoomType>;
  unclaimed: Array<ZoomRoomType>;
}

export interface IntegrationExtraQSysType {
  cores: Record<
    number,
    {
      id: number;
      name: string;
      site: {
        id: number;
        name: string;
      };
      model: string;
      serial: string;
      status: {
        code: number;
        details: string;
        message: string;
      };
      uptime: number;
      firmware: string;
      accessMode: string;
      redundancy: any | null;
      accessLevel: number;
      modelNumber: string;
    }
  >;
  import: {
    devices: number;
    message: string;
    running: boolean;
    spaces: number;
    state: string;
  };
}

export interface IntegrationExtraBrightSignType {
  error: string;
  networks: string[];
  devices: Array<{
    id: number;
    name: string;
    serial: string;
  }>;
}

export enum IntegrationState {
  Active = 'active',
  Disabled = 'disabled',
  Error = 'error',
  Unauthenticated = 'unauthenticated',
}

export interface IntegrationType<ExtraType = IntegrationExtraType> {
  name: string;
  state: IntegrationState;
  updated_at: string;
  params: Record<string, any>;
  extra: ExtraType;
  error: null | any;
}

export type NotificationType = {
  id: number;
  title: string;
  message: string;
  link?: string;
};

export type SystemIssuesType = {
  id: number;
  title: string;
  description: string;
  link?: string;
  entity: { type: string; id: string };
};

export interface UserPermissions {
  models: AccessLevelEnum;
  customers: AccessLevelEnum;
  finance: AccessLevelEnum;
  store_management: AccessLevelEnum;
  fulfillment: AccessLevelEnum;
}

export type UserPermissionNames = keyof UserPermissions;

export enum RefereeType {
  Organization = 'organization',
}

export interface RuleError {
  id: string;
  name: string;
  description: string;
  created_at: string;
}

export interface RuleType {
  id: string;
  name: string;
  active: boolean;
  device_model: string;
  description: string;
  priority: string;
  created_at: string;
  definition: any;
  errors: RuleError[];
}

export type DashboardLayoutListItem<TID = string> = Layout & {
  id: TID;
  name: string;
};

export type DashboardLayout<TID = string> = {
  list: DashboardLayoutListItem<TID>[];
  layouts: Layouts;
};

export type SupportedCommandFieldType = FieldType<{
  value: string;
  label: string;
  order: number;
}>;

export type SupportedCommandType = {
  id: UuidType;
  name: string;
  device_id: string;
  status: string;
  friendly_name: string;
  description: string;
  with_file: null | boolean;
  file_type?: string;
  custom_fields: null | Array<SupportedCommandFieldType>;
  active: boolean;
  timeout_in_seconds: number;
  allow_duplicate_commands: boolean;
  created_at: string;
  updated_at: string;
};

export enum AuthMethodType {
  CloudId = 'cloud_id',
  MacSN = 'mac_sn',
  PubKey = 'pub_key',
  x509 = 'x509',
}

export type DeviceTypeType = 'physical' | 'digital' | 'application';

export enum CommunicationProtocolType {
  HTTPS = 'https',
  MQTT = 'mqtt',
  VPRO = 'vPro',
}

export type TicketStatusType = 'open' | 'replied' | 'review' | 'resolved';

export type TicketType = {
  id: string;
  status: TicketStatusType;
  title: string;
  description: string;
  device_id: string;
  device_name?: string;
  partner: string;
  partner_logo_url: string | null;
  organization: string;
  organization_logo_url: string | null;
  org_seen: boolean;
  partner_seen: boolean;
  created_at: string;
  updated_at: string;
  messages_count: number;
  device?: {
    name: string;
    model: string;
    sn: null | any;
    model_icon?: string;
  };
  chat?: ChatMessageType[];
  space?: {
    name: string;
    path: string;
  };
  org_id?: string;
};

export interface ChatMessageType {
  created_at: string;
  id: number;
  owner: string;
  owner_type: TenantType | 'system';
  system: boolean;
  text: string;
  user: string | null;
}

export interface OrganizationFileResponseType {
  id: string;
  name: string;
  url: string;
  desc: string | null;
  type: string | null;
  version: string | null;
  device_model: string | null;
  device_model_name: string | null;
  space: string | null;
  space_name: string | null;
  public_notes: string | null;
  partner: boolean;
}

export interface PartnerFileResponseType {
  id: string;
  name: string;
  url: string;
  desc: string;
  type: string;
  file_type: string;
  version: string;
  created_at: string;
  device_model_id: string;
  device_model_name: string;
  checksum: string;
  signature: string;
  notes: string;
  public_notes: string;
}

export enum LicenseState {
  Available = 'available',
  Inuse = 'inuse',
  Renew = 'renew',
  Error = 'error',
  Remove = 'remove',
  Removed = 'removed',
  Disable = 'disable',
  Disabled = 'disabled',
}

export type LicenseType = {
  id: string;
  state: LicenseState;
  family: null | any;
  amount: number;
  transferable: boolean;
  expires_at: null | string;
  activated_at: null | string;
  created_at: string;
  duration: null | any;
  partner_display_name: string;
  product_name: string;
  product_id: string;
  product_image: string | null;
  product_subtitle: string | null;
  product_category: string | null;
  product_payment_interval: PeriodEnum;
  device_error_message: string | null;
  device_id: string;
  device_model_id?: string;
  model: string;
  org_license: boolean;
  assigned?: boolean;
  claimed?: boolean;
  notes?: string;
  data?: string;
  signature?: string;
  access_level: AccessLevelEnum;
  params?: {
    sn: string;
  };
  organization: {
    id: string;
    name: string;
  };
};

// Define a utility type for the keys
export type LocationGenericKeys =
  | 'FeatureFlags'
  | 'TabContentComponentProps'
  | 'PathParams'
  | 'VisibleFnExtraParams';

// Define a mapped type for the generics with constraints
export type LocationGeneric<
  T extends Partial<Record<LocationGenericKeys, any>>
> = {
  FeatureFlags: T['FeatureFlags'] extends CommonFeatureFlagsType
    ? T['FeatureFlags']
    : CommonFeatureFlagsType;
  TabContentComponentProps: T['TabContentComponentProps'] extends object
    ? T['TabContentComponentProps']
    : Record<string, unknown>;
  PathParams: T['PathParams'] extends object
    ? T['PathParams']
    : Record<string, string>;
  VisibleFnExtraParams: T['VisibleFnExtraParams'] extends object
    ? T['VisibleFnExtraParams']
    : Record<string, unknown>;
};

export type TabType<
  TFeatureFlags extends CommonFeatureFlagsType = CommonFeatureFlagsType,
  TPortalCapabilities extends CommonPortalCapabilities = CommonPortalCapabilities,
  ComponentProps extends object = Record<string, unknown>,
  VisibleFnExtraParams extends object = Record<string, unknown>,
  TDevice extends object = Record<string, unknown>
> = {
  title: string;
  id: string;
  name?: string;
  Component?: ComponentRendererType<ComponentProps>;
  props?: Record<string, any>;
  tabs?: TabType<
    TFeatureFlags,
    TPortalCapabilities,
    ComponentProps,
    VisibleFnExtraParams,
    TDevice
  >[];
  visible?: TabVisibleFn<
    VisibleFnExtraParams,
    TFeatureFlags,
    TPortalCapabilities
  >;
  disabled?: boolean;
  badge?: ReactNode;
  Counter?: ComponentRendererType<ComponentProps>;
  counterProps?: (device: TDevice) => {
    spaceId?: number;
    device?: TDevice;
    className?: string;
    inverted?: boolean;
    withTooltip?: boolean;
  };
  errors?: number;
  state?: string;
};

export type ProductType = {
  category: string;
  id: number;
  disabled?: boolean;
  owner: string;
  title: string;
  img: string;
  comingSoon?: boolean;
};

export enum ProductPricingModel {
  Standard = 'standard',
  UsageBased = 'usage_based',
  Personalized = 'personalized',
}

// TODO: Replace all usages
export type SetRoute = (path: string) => void;

export type ModelType = {
  id: string;
};

export type MethodType = 'POST' | 'GET' | 'PUT' | 'PATCH' | 'DELETE';

export enum PeriodEnum {
  Monthly = 'monthly',
  Yearly = 'yearly',
  OneTime = 'one_time',
}

export type UuidType = string;
export type AccessLevelType = 0 | 1 | 2 | 3;

export type ExpertType = {
  id: string;
  name: string;
  image: string;
  description: string;
  email?: string;
  phone?: string;
  locations?: string;
  expertise?: string;
};

export interface StateDumpType {
  id: string;
  filename: string;
  device_id: string;
  created_at: string;
  access_count: number;
  access_token: string;
  mime_type: string;
}

export type ValidationErrorType = {
  data: string;
  error: string;
  field: string;
  path: Array<string>;
};

export interface TestDeviceType {
  access_token: string;
  created_at: string;
  device_model_id: string;
  firmware: string;
  id: string;
  mac: null | string;
  cloud_id: string;
  sn: null | string;
  telemetry_count?: boolean;
  test: boolean;
  note: null | string;
  hub_url: null | string;
}

export interface TestDeviceWithTelemetryType extends TestDeviceType {
  telemetries: Array<TelemetryType>;
}

// Shipping

export enum ShipmentStatusType {
  Preparing = 'preparing_for_shipment',
  Shipped = 'shipped',
  Delivered = 'delivered',
}

// Shipping - Partners

export type PartnerInvoiceItemType = {
  name: string;
  amount: number;
};

export interface OrderPreviewItemType {
  id: string;
  name: string;
  quantity: number;
  selected_payment_interval: PeriodEnum;
  charges: {
    one_time?: {
      amount_in_scu: number;
    };
    monthly?: {
      charge_date: string;
      amount_in_scu: number;
    };
    yearly?: {
      charge_date: string;
      amount_in_scu: number;
    };
  };
}

export interface EstimatedTax {
  name: string;
  rate: number;
  amount: number;
}

export interface PreviewOneTimeChargeSummary {
  type: 'one_time';
  amount_in_scu: number;
}

export interface PreviewProrataChargeSummary {
  type: 'prorata';
  amount_in_scu: number;
  start_date: string;
  end_date: string;
}

export interface MapCoordinatesType extends GoogleMapReact.Coords {}

export interface OrganizationAddressType {
  id: string;
  line_1: string;
  line_2: string | null;
  line_3: string | null;
  phone_number: string | null;
  city: string;
  state: string | null;
  country: string;
  zip: string | null;
  display_name: string;
  receiver_name: null;
  receiver_tax_id: null;
  eori: string | null;
  note_to_shipper: string | null;
}

export enum PaymentMethodEnum {
  CreditCard = 'credit_card',
  Lab = 'lab',
  ACHTransfer = 'ach_transfer',
  PurchaseOrder = 'purchase_order',
}

const AUDIT_LOG_TEMPLATES = {
  user_created: 'user_created',
  user_added_to_group: 'user_added_to_group',
  user_removed_from_group: 'user_removed_from_group',
  changed_access_level: 'changed_access_level',
  removed_access: 'removed_access',
  group_created: 'group_created',
  update_group_permissions: 'update_group_permissions',
  device_claimed: 'device_claimed',
  device_deleted: 'device_deleted',
  device_command_sent: 'device_command_sent',
  integration_created: 'integration_created',
  integration_updated: 'integration_updated',
  customer_created: 'customer_created',
  model_created: 'model_created',
  model_published: 'model_published',
  product_created: 'product_created',
  product_updated: 'product_updated',
  product_deleted: 'product_deleted',
  invited_existing_partner: 'invited_existing_partner',
  invited_new_partner: 'invited_new_partner',
  partner_accepted_invitation: 'partner_accepted_invitation',
  partner_rejected_invitation: 'partner_rejected_invitation',
  attribute_updated: 'attribute_updated',
} as const;

export type AuditLogTemplates = keyof typeof AUDIT_LOG_TEMPLATES;

export interface AuditLogParamsType {
  id?: string;
  model?: string;
  name: string;
  type: string;
  value?: string | boolean;
  display_name: string;
}

export interface AuditLogType {
  id: string;
  full_message: string;
  created_at: string;
  user?: {
    id: string;
    name: string;
    email?: string;
  };
  template: AuditLogTemplates;
  params: Array<AuditLogParamsType>;
}

export interface TaxGroupProductType {
  id: string;
  name: string;
  image_url: string;
}

export interface UseNotes {
  note_to_seller: string;
}

export interface UseCheckout {
  isSameAddress: {
    checked: StateType['store']['isSameAddress'];
    onChange: (isSameAddress: boolean) => void;
  };
  address: {
    billing: {
      selected: StateType['store']['billingAddressId'];
      onChange: (id: string | null) => void;
    };
    shipping: {
      selected: StateType['store']['shippingAddressId'];
      onChange: (id: string | null) => void;
    };
  };
  creditCard: {
    selected: StateType['store']['creditCardId'];
    onChange: (id: string | null) => void;
  };
  paymentMethod: {
    selected: StateType['store']['paymentMethod'];
    onChange: (paymentMethod: PaymentMethodEnum) => void;
  };
  notes_to_seller: {
    selected: StateType['store']['cart']['notes'];
    onChange: (notes: string) => void;
  };
}

export type DeviceModelIconName =
  | 'Mic'
  | 'Camera'
  | 'Cast'
  | 'Bell'
  | 'BarChart'
  | 'Disc'
  | 'Codepen'
  | 'Cpu'
  | 'Globe'
  | 'Box'
  | 'Briefcase'
  | 'Trash2'
  | 'Database'
  | 'Key'
  | 'Monitor'
  | 'Rss'
  | 'Users'
  | 'Phone'
  | 'Package'
  | 'Settings'
  | 'Pocket'
  | 'Video'
  | 'Volume'
  | 'Sliders'
  | 'Power'
  | 'Inbox'
  | 'Speaker'
  | 'Watch'
  | 'Voicemail'
  | 'Terminal'
  | 'Lock'
  | 'Grid'
  | 'Tablet'
  | 'Folder'
  | 'CreditCard'
  | 'Calendar'
  | 'Archive'
  | 'Aperture'
  | 'Coffee'
  | 'Eye'
  | 'HardDrive'
  | 'Layers'
  | 'Server'
  | 'Battery'
  | 'Target'
  | 'Zap'
  | 'LifeBuoy'
  | 'Printer';
