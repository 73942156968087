import { createStyles, Group } from '@mantine/core';
import React from 'react';

import { SettingCard } from './SettingCard';
import { SettingPage } from './settings-page.types';

export interface SettingsPageGridProps {
  basePath: string;
  pages: SettingPage[];
}

export function SettingsPageGrid({ pages, basePath }: SettingsPageGridProps) {
  const { classes } = useStyles();
  const visiblePages = pages.filter((page) => page.visible !== false);

  return (
    <Group spacing="xl" className={classes.container}>
      {visiblePages.map((page) => (
        <SettingCard
          key={page.id}
          icon={page.icon}
          title={page.title}
          description={page.description}
          path={`${basePath}${page.path}`}
          ribbon={page.ribbon}
          badge={page.badge}
        />
      ))}
    </Group>
  );
}

const useStyles = createStyles((theme) => ({
  container: {
    height: 'fit-content',
  },
}));
