import {
  any,
  entries,
  find,
  forEach,
  get,
  includes,
  isEmpty,
  keys,
  map,
  omit,
  omitBy,
  set,
  setWith,
  unset,
} from 'lodash/fp';

import {
  AddProductToCartPayload,
  CheckCartItemsPayload,
  CheckSelectedAddressPayload,
  CheckSelectedCreditCardPayload,
  CheckSelectedCurrencyPayload,
  OrganizationStoreListingPriceType,
  PaymentMethodEnum,
  PeriodEnum,
  ProductPricingModel,
  StoreStateType,
} from '@portals/types';

import {
  ADD_PRODUCT_TO_CART,
  CHECK_CART_ITEMS,
  CHECK_SELECTED_ADDRESS,
  CHECK_SELECTED_CREDIT_CARD,
  CHECK_SELECTED_CURRENCY,
  CLEAR_CART,
  REMOVE_PRODUCT_FROM_CART,
  SET_BILLING_ADDRESS_ID,
  SET_CREDIT_CARD_ID,
  SET_IS_SAME_ADDRESS,
  SET_PAYMENT_METHOD,
  SET_PRODUCT_QUANTITY,
  SET_SELLER_NOTES,
  SET_SHIPPING_ADDRESS_ID,
  SET_STORE_CURRENCY,
  SET_SWITCH_NOTES,
  SWITCH_TENANT,
} from '../constants';

function getCartDataFromLocalStorage() {
  const cartAsString = localStorage.getItem('cart');

  if (!cartAsString) return;

  try {
    const cart = JSON.parse(cartAsString) as StoreStateType;

    if (cart.version !== 2) return;

    return cart;
  } catch (e) {
    console.error(e);

    return;
  }
}

const clearedState: StoreStateType = {
  storeCurrency: null,
  cart: {},
  creditCardId: null,
  billingAddressId: null,
  shippingAddressId: null,
  isSameAddress: false,
  paymentMethod: PaymentMethodEnum.CreditCard,
  version: 2,
};

const initialState = getCartDataFromLocalStorage() || clearedState;

const addProductToCart = (
  { id, quantity, period, price }: AddProductToCartPayload,
  state: StoreStateType
): StoreStateType => {
  let newState = state;
  const existingProductInCart = get([id, period], newState.cart?.items);

  if (existingProductInCart) {
    newState = set(
      ['cart', 'items', id, period, 'quantity'],
      existingProductInCart.quantity + quantity,
      state
    );
  } else {
    newState = setWith(
      Object,
      ['cart', 'items', id, period],
      { quantity, price },
      state
    );
  }

  return checkIfCartIsEmpty(newState);
};

const setProductQuantity = (
  { id, quantity, period, price }: AddProductToCartPayload,
  state: StoreStateType
): StoreStateType => {
  const existingProductInCart = get(['items', id, period], state.cart);
  let newState: StoreStateType;

  if (existingProductInCart) {
    if (quantity <= 0) {
      const updatedProductByPeriodQuantity = omit(
        [period],
        state.cart?.items?.[id]
      );

      if (isEmpty(updatedProductByPeriodQuantity)) {
        newState = unset(['cart', 'items', id], state);
      } else {
        newState = unset(['cart', 'items', id, period], state);
      }
    } else {
      newState = set(
        ['cart', 'items', id, period, 'quantity'],
        quantity,
        state
      );
    }
  } else {
    newState = setWith(
      Object,
      ['cart', 'items', id, period],
      { quantity, price },
      state
    );
  }

  return checkIfCartIsEmpty(newState);
};

const removeProductFromCart = (
  { id, period }: Omit<AddProductToCartPayload, 'quantity'>,
  state: StoreStateType
): StoreStateType => {
  const updatedProductByPeriodQuantity = omit(
    [period],
    state.cart?.items?.[id]
  );

  if (isEmpty(updatedProductByPeriodQuantity)) {
    const updatedCartItems = omit([id], state.cart?.items);

    if (isEmpty(updatedCartItems)) {
      return {
        ...state,
        cart: {},
      };
    }

    return {
      ...state,
      cart: {
        ...state.cart,
        items: updatedCartItems,
      },
    };
  }

  return checkIfCartIsEmpty(unset(['cart', 'items', id, period], state));
};

const checkIfCartIsEmpty = (state: StoreStateType): StoreStateType => {
  const isCartEmpty = isEmpty(state.cart?.items);

  if (isCartEmpty) {
    return {
      ...state,
      cart: {},
    };
  }

  return state;
};

function checkSelectedCurrency(
  { currencies, defaultCurrency }: CheckSelectedCurrencyPayload,
  state: StoreStateType
): StoreStateType {
  let cart = state.cart;
  let storeCurrency = state.storeCurrency;

  if (!storeCurrency || !includes(storeCurrency, currencies)) {
    storeCurrency = defaultCurrency;
    cart = {};
  }

  return {
    ...state,
    storeCurrency,
    cart,
  };
}

function checkCartItems(
  { storeListings }: CheckCartItemsPayload,
  state: StoreStateType
): StoreStateType {
  const existingStoreListingIds = map('id', storeListings);

  const cartItems = omitBy(
    (_, storeListingId) => !includes(storeListingId, existingStoreListingIds),
    state.cart.items
  );

  if (isEmpty(cartItems)) {
    return {
      ...state,
      cart: {},
    };
  }

  const storeListingIdsToRemove: string[] = [];

  // Check that all pricing options keys are still available in store listing
  forEach(([storeListingId, pricingOptions]) => {
    const storeListing = find({ id: storeListingId }, storeListings);
    const selectedCartItemPeriods = keys(
      pricingOptions
    ) as OrganizationStoreListingPriceType[];

    let existingStoreListingPeriods: OrganizationStoreListingPriceType[] = [];

    if (
      storeListing?.product?.pricing_model === ProductPricingModel.Personalized
    ) {
      // We treat personalized prices products as one_time
      existingStoreListingPeriods = [PeriodEnum.OneTime];
    } else {
      existingStoreListingPeriods = map(
        'type',
        storeListing?.prices?.[0]?.pricing_options
      );
    }

    const itemHasRemovedPricingOption = any(
      (period) => !includes(period, existingStoreListingPeriods),
      selectedCartItemPeriods
    );

    if (itemHasRemovedPricingOption) {
      storeListingIdsToRemove.push(storeListingId);
    }
  }, entries(cartItems));

  if (!isEmpty(storeListingIdsToRemove)) {
    const updatedCartItems = omit(storeListingIdsToRemove, cartItems);

    if (isEmpty(updatedCartItems)) {
      return {
        ...state,
        cart: {},
      };
    }

    return {
      ...state,
      cart: {
        ...state.cart,
        items: updatedCartItems,
      },
    };
  }

  return state;
}

function checkSelectedAddress(
  { addresses }: CheckSelectedAddressPayload,
  state: StoreStateType
): StoreStateType {
  const existingAddressesIds = map('id', addresses);

  const shippingIdExists = includes(
    state.shippingAddressId,
    existingAddressesIds
  );

  const billingIdExists = includes(
    state.billingAddressId,
    existingAddressesIds
  );

  return {
    ...state,
    shippingAddressId: shippingIdExists ? state.shippingAddressId : null,
    billingAddressId: billingIdExists ? state.billingAddressId : null,
  };
}

function checkSelectedCreditCard(
  { creditCards }: CheckSelectedCreditCardPayload,
  state: StoreStateType
): StoreStateType {
  const creditCardIds = map('id', creditCards);

  const creditCardIdExists = includes(state.creditCardId, creditCardIds);

  return {
    ...state,
    creditCardId: creditCardIdExists ? state.creditCardId : null,
  };
}

const reducer = (state = initialState, action) => {
  let newState = state;

  switch (action.type) {
    case ADD_PRODUCT_TO_CART:
      newState = addProductToCart(action.payload, newState);
      break;

    case SET_PRODUCT_QUANTITY:
      newState = setProductQuantity(action.payload, newState);
      break;

    case REMOVE_PRODUCT_FROM_CART:
      newState = removeProductFromCart(action.payload, newState);
      break;

    case CHECK_SELECTED_CURRENCY:
      newState = checkSelectedCurrency(action.payload, newState);
      break;

    case CHECK_CART_ITEMS:
      newState = checkCartItems(action.payload, newState);
      break;

    case CHECK_SELECTED_ADDRESS:
      newState = checkSelectedAddress(action.payload, newState);
      break;

    case CHECK_SELECTED_CREDIT_CARD:
      newState = checkSelectedCreditCard(action.payload, newState);
      break;

    case SET_STORE_CURRENCY:
      newState = set('storeCurrency', action.payload.currency, newState);
      break;

    case SET_SELLER_NOTES:
      newState = set(['cart', 'notes'], action.payload.notes, newState);
      break;

    case SET_SWITCH_NOTES:
      newState = set(
        ['cart', 'notesSwitchStatus'],
        action.payload.status,
        newState
      );
      break;

    case CLEAR_CART:
      newState = {
        ...newState,
        isSameAddress: false,
        cart: {},
      };
      break;

    case SET_CREDIT_CARD_ID:
      newState = {
        ...newState,
        creditCardId: action.payload.id,
      };

      break;

    case SET_BILLING_ADDRESS_ID:
      newState = {
        ...newState,
        billingAddressId: action.payload.id,
      };

      break;

    case SET_SHIPPING_ADDRESS_ID:
      newState = {
        ...newState,
        shippingAddressId: action.payload.id,
        billingAddressId: newState.isSameAddress
          ? action.payload.id
          : newState.billingAddressId,
      };

      break;

    case SET_IS_SAME_ADDRESS:
      newState = {
        ...newState,
        isSameAddress: action.payload.isSameAddress,
        billingAddressId: action.payload.isSameAddress
          ? newState.shippingAddressId
          : newState.billingAddressId,
      };

      break;

    case SET_PAYMENT_METHOD:
      newState = set('paymentMethod', action.payload.paymentMethod, state);
      break;

    case SWITCH_TENANT:
      newState = clearedState;
      break;

    default:
      break;
  }

  localStorage.setItem('cart', JSON.stringify(newState));

  return newState;
};

export default reducer;
