import {
  createStyles,
  Group,
  px,
  Stack,
  StackProps,
  Text,
  useMantineTheme,
} from '@mantine/core';
import { useElementSize } from '@mantine/hooks';
import { motion } from 'framer-motion';
import { isUndefined } from 'lodash';
import React, { useCallback, useMemo } from 'react';

import { FORMATTERS } from '../../../utils/formatters';
import {
  NumberFormatType,
  ScaleType,
  WidgetColorType,
} from '../../widgets.types';
import { GaugeIndicatorSegmentType } from '../form';

export interface GaugeIndicatorWidgetProps {
  title: string;
  value: number;
  unit?: string | null;
  min: number;
  max: number;
  scaleType?: ScaleType;
  numOfDecimals?: number;
  numberFormat?: NumberFormatType;
  segments: Array<GaugeIndicatorSegmentType>;
  stackProps?: StackProps;
}

const REFERENCE_SIZE = 265;
const BASE_BAR_WIDTH = 5;
const BASE_BAR_HEIGHT = 28;
const TOTAL_ANGLE = 360 - 60;
const BASE_GAP_WIDTH = 7;

function getGaugeParams({
  width,
  height,
  padding,
  anglePerPixel,
}: {
  width: number | undefined;
  height: number | undefined;
  padding: number;
  anglePerPixel: number;
}): {
  radius: number;
  barsCount: number;
  rotationAngle: number;
} {
  const radius =
    width && height
      ? Math.min(
          width - padding - BASE_BAR_WIDTH,
          height - padding - BASE_BAR_WIDTH
        ) / 2
      : 0;

  const gapAngle = BASE_GAP_WIDTH * anglePerPixel;
  const anglePerBarWithGap = BASE_BAR_WIDTH + gapAngle;

  const barsCount = Math.floor(TOTAL_ANGLE / anglePerBarWithGap);
  const gapCount = Math.max(barsCount - 1, 0);

  const effectiveTotalGapsAngle = gapCount * gapAngle;
  const effectiveTotalBarsAngle = barsCount * BASE_BAR_WIDTH;
  const effectiveTotalAngle = effectiveTotalGapsAngle + effectiveTotalBarsAngle;

  const rotationAngle = (360 - effectiveTotalAngle) / 2 + 180; // Add 180 degrees

  return {
    radius,
    barsCount,
    rotationAngle,
  };
}

export function GaugeIndicatorWidget({
  title,
  value,
  unit,
  segments,
  min,
  max,
  scaleType = 'linear',
  numberFormat = 'none',
  numOfDecimals = 1,
  stackProps = {},
}: GaugeIndicatorWidgetProps) {
  const { classes, theme } = useStyles();
  const { ref, width, height } = useElementSize();

  const gaugeSize =
    width && height
      ? Math.min(width - px(theme.spacing.xl), height - px(theme.spacing.xl))
      : 0;

  // Calculate the scaling factor based on the target gauge size (265x265)
  const scalingFactor = gaugeSize / REFERENCE_SIZE;

  // Calculate the reference radius, circumference, and anglePerPixel for reference gauge size
  const referenceRadius = (REFERENCE_SIZE - px(theme.spacing.xl)) / 2;
  const referenceCircumference = 2 * Math.PI * referenceRadius;
  const anglePerPixel = 360 / referenceCircumference;

  const { barsCount, radius, rotationAngle } = getGaugeParams({
    width,
    height,
    anglePerPixel,
    padding: px(theme.spacing.xl),
  });

  const bars = useMemo(() => {
    if (!width || !height) return [];

    const barAngle = TOTAL_ANGLE / barsCount;

    const getColor = (valuePercentage: number) => {
      for (const segment of segments) {
        if (valuePercentage <= segment.max) return segment.color;
      }

      return 'gray.3';
    };

    const valuePercentage = Math.min(((value - min) / (max - min)) * 100, 100);
    let adjustedValuePercentage;

    if (scaleType === 'log') {
      const minValue = Math.max(min, 1);
      const maxValue = Math.max(max, 1);
      const logMin = Math.log10(minValue);
      const logMax = Math.log10(maxValue);
      const logValue = Math.log10(value);

      adjustedValuePercentage = ((logValue - logMin) / (logMax - logMin)) * 100;
    } else {
      adjustedValuePercentage = valuePercentage;
    }

    const barColor = getColor(valuePercentage);

    const splitIndex = Math.round((adjustedValuePercentage / 100) * barsCount);

    return new Array(barsCount).fill(null).map((_, index) => {
      return (
        <GaugeBar
          key={index}
          color={index < splitIndex ? barColor : 'gray.3'}
          angle={barAngle}
          index={index}
          width={BASE_BAR_WIDTH * scalingFactor}
          height={BASE_BAR_HEIGHT * scalingFactor}
          radius={radius}
        />
      );
    });
  }, [
    scalingFactor,
    width,
    height,
    barsCount,
    value,
    min,
    max,
    segments,
    radius,
    scaleType,
  ]);

  const formatter = useCallback(
    (value: number | undefined) => {
      if (isUndefined(value)) return 'N/A';

      const adjustedValue = FORMATTERS[numberFormat](value, numOfDecimals);

      if (isUndefined(adjustedValue)) return 'N/A';

      return adjustedValue;
    },
    [numberFormat, numOfDecimals]
  );

  return (
    <Stack
      className={classes.container}
      p="xl"
      h="100%"
      w="100%"
      bg="white"
      justify="center"
      align="center"
      spacing="xl"
      ref={ref}
      {...stackProps}
    >
      <Group w="100%" position="left">
        <Text
          size="md"
          data-testid="dashboard-gauge-indicator-widget-name"
          color="gray.5"
        >
          {title}
        </Text>
      </Group>

      <svg width="100%" height="100%" viewBox={`0 0 ${gaugeSize} ${gaugeSize}`}>
        {unit ? (
          <text
            x="50%"
            y="50%"
            dominantBaseline="middle"
            textAnchor="middle"
            fontFamily="Nunito"
            fontSize={px(theme.fontSizes.sm) * scalingFactor}
            fontWeight={600}
            fill={theme.colors.gray[6]}
            dy={-15 * scalingFactor}
          >
            {unit}
          </text>
        ) : null}

        <text
          x="50%"
          y="50%"
          dominantBaseline="middle"
          textAnchor="middle"
          fontSize={46 * scalingFactor * (numberFormat === 'none' ? 1 : 0.75)}
          fontFamily="Nunito"
          fill={theme.colors.gray[9]}
          dy={BASE_BAR_HEIGHT * scalingFactor - 5}
        >
          {formatter(value)}
        </text>

        <g
          transform={`rotate(${rotationAngle}, ${gaugeSize / 2}, ${
            gaugeSize / 2
          })`}
        >
          {bars}
        </g>
      </svg>
    </Stack>
  );
}

interface GaugeBarProps {
  color: WidgetColorType;
  angle: number;
  index: number;
  width: number;
  height: number;
  radius: number;
}

function GaugeBar({
  color,
  angle,
  index,
  width,
  height,
  radius,
}: GaugeBarProps) {
  const theme = useMantineTheme();
  const rotation = index * angle;
  const cx = radius;
  const cy = radius;

  return (
    <motion.rect
      x={0}
      y={0 - radius}
      width={width}
      height={height}
      animate={{
        fill: theme.fn.themeColor(color),
      }}
      transition={{
        duration: 0.15,
      }}
      rx={2}
      ry={2}
      transform={`rotate(${rotation}, ${cx}, ${cy}) translate(${cx}, ${cy})`}
    />
  );
}

const useStyles = createStyles((theme) => ({
  container: { borderRadius: theme.radius.lg },
}));
