import {
  Button,
  Checkbox,
  Group,
  Input,
  LoadingOverlay,
  Stack,
  Text,
  TextInput,
  Title,
} from '@mantine/core';
import { useForm, yupResolver } from '@mantine/form';
import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { boolean, InferType, object, string } from 'yup';

import {
  useErrorByActionName,
  useIsPending,
  useSetRoute,
} from '@portals/redux';
import { createOrg } from '@portals/redux/actions/auth';

import { TermsAndPolicy } from '../../components/TermsAndPolicy';
import { useCommonPortalConfig } from '../../hooks/portal-config';
import { FormWrapper } from './common';
import {
  PasswordInputWithRequirements,
  yupPasswordValidator,
} from './PasswordInputWithRequirements';

interface SignUpProps {
  onBack?: {
    to: string;
    callback: () => void;
  };
  isForceSignup?: boolean;
  afterAuthPath?: string;
}

const schema = object({
  organization: string().required(),
  name: string().required(),
  email: string().email().required(),
  password: yupPasswordValidator,
  terms: boolean()
    .oneOf(
      [true],
      'Please read and agree to our terms and conditions and privacy policy by checking the box'
    )
    .required(),
});

export function SignUp({
  onBack,
  isForceSignup = false,
  afterAuthPath = '/',
}: SignUpProps) {
  const dispatch = useDispatch();
  const isReferral = !!localStorage.getItem('referral');

  const setRoute = useSetRoute();
  const serverError = useErrorByActionName('createOrg');
  const creating = useIsPending('createOrg');
  const portalConfig = useCommonPortalConfig();

  const form = useForm<InferType<typeof schema>>({
    initialValues: {
      organization: '',
      name: '',
      email: '',
      password: '',
      terms: false,
    },
    validate: yupResolver(schema),
  });

  const onSubmit = (values: typeof form.values) => {
    const referral = localStorage.getItem('referral') || '';

    const { name, organization, email, password, terms } = values;

    dispatch(
      createOrg(
        organization,
        name,
        email,
        password,
        terms,
        referral,
        afterAuthPath
      )
    );
  };

  useEffect(() => {
    if (!isReferral && !isForceSignup && portalConfig.data?.signup !== true) {
      setRoute('/auth/sign-in');
    }
  }, [isReferral, isForceSignup, setRoute, portalConfig.data?.signup]);

  if (portalConfig.isLoading) return <LoadingOverlay visible />;

  return (
    <FormWrapper id="sign-up">
      <Stack spacing="lg" className="sign-up-container">
        <Group position="apart" align="baseline">
          <Title order={1} className="auth-page-title">
            Sign-Up
          </Title>

          <Text color="dimmed" size="sm" align="center">
            <Group spacing={6}>
              Already have an account?
              <Link
                className="sign-up-link-sign-in"
                to={onBack ? onBack.to : '/auth/sign-in'}
                onClick={() => {
                  if (onBack) {
                    onBack.callback();
                  }
                }}
              >
                Sign in
              </Link>
            </Group>
          </Text>
        </Group>

        <form onSubmit={form.onSubmit(onSubmit)} noValidate>
          <Stack spacing="md">
            <TextInput
              required
              label="Organization Name"
              placeholder="Name your organization"
              {...form.getInputProps('organization')}
            />

            <TextInput
              required
              label="Name"
              placeholder="Your full name"
              {...form.getInputProps('name')}
            />

            <TextInput
              required
              label="Email"
              type="email"
              placeholder="Company email address"
              {...form.getInputProps('email')}
            />

            <PasswordInputWithRequirements
              error={form.errors.password}
              value={form.values.password}
              onChange={(e) => form.setFieldValue('password', e.target.value)}
            />

            <Checkbox
              required
              label={<TermsAndPolicy />}
              {...form.getInputProps('terms')}
            />

            {serverError && <Input.Error>{serverError}</Input.Error>}
          </Stack>

          <Button fullWidth mt="xl" type="submit" loading={creating}>
            Sign Up
          </Button>
        </form>
      </Stack>
    </FormWrapper>
  );
}
