import { createStyles, Group } from '@mantine/core';
import React, { FC } from 'react';

import { useActionDispatch, useIsSidebarOpen } from '@portals/redux';
import { toggleSidebar as toggleSidebarAction } from '@portals/redux/actions/sidebarActions';
import { DesignType } from '@portals/types';

import { XyteLogo } from '../../../assets';
import { useCommonDesign } from '../../../hooks/portal-config';
import { SidebarToggleButton } from './SidebarToggleButton';

export const SidebarFooter: FC = () => {
  const design = useCommonDesign();
  const { classes } = useStyles({ design });
  const isSidebarOpen = useIsSidebarOpen();
  const toggleSidebar = useActionDispatch(toggleSidebarAction);

  const showXyteCredits =
    design?.sign_in_page?.powered_by_xyte_label !== false && isSidebarOpen;
  return (
    <Group
      align="center"
      position={isSidebarOpen ? 'apart' : 'center'}
      className={classes.footer}
    >
      <Group align="center" spacing="xs" className="powered-by">
        {showXyteCredits && (
          <>
            <span>Powered by</span>
            <XyteLogo height={13} />
          </>
        )}
      </Group>

      <SidebarToggleButton
        isOpened={isSidebarOpen}
        toggleSidebar={toggleSidebar}
      />
    </Group>
  );
};

const useStyles = createStyles((theme, { design }: { design: DesignType }) => ({
  footer: {
    padding: theme.spacing.md,
    borderTop: `1px solid ${theme.fn.rgba(
      design?.navigation_menu?.menu_text_color || theme.colors.blue_gray[6],
      0.2
    )}`,
    color:
      design?.navigation_menu?.menu_text_color || theme.colors.blue_gray[6],
  },
}));
