import { size } from 'lodash/fp';

import { TenantType } from '@portals/types';

import { useAppConfig } from '../../../../context';
import { useCurrentUserAccessibleTenants } from '../../../../hooks/users';
import { usePermissionAccess } from '../../../permission-access/use-permission-access';

export const useShouldDisplayTenantMenu = () => {
  const { tenantType } = useAppConfig();
  const { isSuperAdmin } = usePermissionAccess();
  const accessibleTenants = useCurrentUserAccessibleTenants();

  const numOfAccessibleTenants = size(accessibleTenants);

  const hasMultipleTenants = numOfAccessibleTenants > 1;
  const canCreateTenant = tenantType === TenantType.Partner && isSuperAdmin;

  return isSuperAdmin || hasMultipleTenants || canCreateTenant;
};
