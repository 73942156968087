import { ActionIcon, useMantineTheme } from '@mantine/core';
import React from 'react';
import { useSelector } from 'react-redux';

import { ReactComponent as InfoCircle } from '@portals/icons/linear/info-circle.svg';
import { ReactComponent as Notification } from '@portals/icons/linear/notification.svg';
import { getNotifications } from '@portals/redux';

import { useCommonDesign } from '../../../hooks/portal-config';
import NavbarDropdownItem from '../../NavbarDropdownItem';
import { NavbarDropdown } from './NavbarDropdown';

export const Notifications = () => {
  const theme = useMantineTheme();
  const notifications = useSelector(getNotifications);
  const count = notifications?.length;
  const design = useCommonDesign();

  return (
    <NavbarDropdown
      header="System notifications"
      icon={() => (
        <ActionIcon
          variant="transparent"
          size="lg"
          sx={{
            color:
              design?.navigation_menu?.menu_text_color ||
              theme.colors.blue_gray[6],
          }}
        >
          <Notification />
        </ActionIcon>
      )}
      count={count}
    >
      {notifications.map(({ id, title, message, link }) => (
        <NavbarDropdownItem
          key={id}
          title={title}
          icon={
            <InfoCircle width={18} height={18} color={theme.colors.red[4]} />
          }
          message={message}
          link={link}
          spacing
        />
      ))}
    </NavbarDropdown>
  );
};
