import {
  Anchor,
  Button,
  Group,
  PasswordInput,
  Stack,
  Text,
  TextInput,
  Title,
  useMantineTheme,
} from '@mantine/core';
import { AnimatePresence, motion } from 'framer-motion';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import { getAuthError, useAuth, useIsPending } from '@portals/redux';
import { signIn } from '@portals/redux/actions/auth';
import { setRoute } from '@portals/redux/actions/routing';
import { TenantType } from '@portals/types';
import { FADE_IN_OUT, ToS } from '@portals/ui';

import { FormWrapper } from './common';
import SSO from './SSO';
import { SignInProps } from './types';
import { useAppConfig } from '../../context';
import { useCommonPortalConfig } from '../../hooks/portal-config';

export function SignIn({
  isForceSignup = false,
  isPreview = false,
  isForgotPasswordVisible = true,
  onSignUp,
  afterAuthPath,
  config,
}: SignInProps) {
  const theme = useMantineTheme();
  const { tenantType } = useAppConfig();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  const portalConfig = useCommonPortalConfig({
    enabled: !isPreview && tenantType !== TenantType.Partner,
  });

  const adjustedConfig =
    tenantType === TenantType.Partner ? config : portalConfig.data;

  const auth = useAuth();
  const authError = useSelector(getAuthError);
  const dispatch = useDispatch();

  const withSignup = adjustedConfig?.signup || false;
  const isSigningIn = useIsPending('signIn');

  const setRouteTimeout = useRef<ReturnType<typeof setTimeout>>(null);

  const tenantId = JSON.parse(localStorage.getItem('last_tenant_id'));

  const handleInputChange = (handler) => (e) => {
    if (!isPreview) {
      handler(e.target.value);
    }
  };

  const handleSubmit = (e) => {
    if (!isPreview) {
      e.preventDefault();
      dispatch(signIn(email, password, tenantType, tenantId));
    }
  };

  useEffect(
    function redirectedAuthenticatedUser() {
      if (isPreview || !auth?.token) return;

      let path;

      if (afterAuthPath) {
        path = afterAuthPath;
      } else {
        path = localStorage.getItem('afterAuth') || '/';
        localStorage.removeItem('afterAuth');
      }

      setRouteTimeout.current = setTimeout(() =>
        dispatch(setRoute(path, true))
      );

      return () => {
        if (setRouteTimeout.current) {
          clearTimeout(setRouteTimeout.current);
          setRouteTimeout.current = null;
        }
      };
    },
    [isPreview, auth?.token, afterAuthPath, dispatch]
  );

  useEffect(
    function redirectToChangePassword() {
      if (isPreview || !auth?.change || !!auth?.token) return;

      setRouteTimeout.current = setTimeout(() =>
        dispatch(setRoute('/auth/change-password', true))
      );

      return () => {
        if (setRouteTimeout.current) {
          clearTimeout(setRouteTimeout.current);
          setRouteTimeout.current = null;
        }
      };
    },
    [auth?.change, auth?.token, isPreview, dispatch]
  );

  const isReferral = !!localStorage.getItem('referral');

  const isSignupVisible =
    isForceSignup ||
    isReferral ||
    (withSignup && tenantType === TenantType.Organization && !isPreview);

  const onSignUpWrapper = useCallback(
    (event) => {
      event.preventDefault();

      if (!isSignupVisible) return;
      else if (onSignUp) onSignUp();
      else dispatch(setRoute('/auth/sign-up', true));
    },
    [dispatch, isSignupVisible, onSignUp]
  );

  return (
    <FormWrapper id="sign-in">
      <form onSubmit={handleSubmit} className="sign-in-container">
        <Stack>
          <Group position="apart" mb="lg" align="baseline">
            <Title order={1} className="auth-page-title">
              Sign-In
            </Title>

            {isSignupVisible && !isPreview ? (
              <Text color="dimmed" size="sm" align="center">
                <Group spacing={6}>
                  Don't have an account yet?
                  <Anchor size="sm" onClick={onSignUpWrapper}>
                    Sign up
                  </Anchor>
                </Group>
              </Text>
            ) : null}
          </Group>

          <TextInput
            label="Email"
            disabled={isPreview}
            type="email"
            name="email"
            placeholder="Enter your email"
            value={email}
            onChange={handleInputChange(setEmail)}
            mb="md"
            data-testid="email-input"
          />

          <PasswordInput
            label="Password"
            disabled={isPreview}
            name="password"
            placeholder="Enter your password"
            value={password}
            onChange={handleInputChange(setPassword)}
            mb="md"
            data-testid="password-input"
          />

          <AnimatePresence>
            {authError && !isPreview ? (
              <motion.p
                {...FADE_IN_OUT}
                className="text-danger auth-page-error"
                data-testid="login-error"
              >
                {authError}
              </motion.p>
            ) : null}
          </AnimatePresence>

          {isForgotPasswordVisible ? (
            <Group position="right">
              <Link
                to="/auth/reset-password"
                style={{
                  color:
                    adjustedConfig?.design?.sign_in_page?.primary_color ||
                    theme.colors.blue_accent[4],
                }}
              >
                Forgot password?
              </Link>
            </Group>
          ) : null}

          <Button
            loading={isSigningIn}
            disabled={!isPreview && (isSigningIn || !email || !password)}
            size="sm"
            type="submit"
            mt="md"
            mb="md"
            bg={
              adjustedConfig?.design?.sign_in_page?.primary_color ||
              theme.colors.blue_accent[4]
            }
            data-testid="sign-in-button"
            styles={(theme) => ({
              root: {
                color:
                  adjustedConfig?.design?.sign_in_page?.text_color ||
                  theme.white,

                '&:hover': {
                  backgroundColor: theme.fn.darken(
                    adjustedConfig?.design?.sign_in_page?.primary_color ||
                      theme.colors.blue_accent[4],
                    0.2
                  ),
                },
              },
            })}
          >
            Sign In
          </Button>

          {isPreview ? null : (
            <SSO tenantId={tenantId} tenantType={tenantType} />
          )}

          <ToS label={'clicking on "Sign In"'} />
        </Stack>
      </form>
    </FormWrapper>
  );
}
