import { useQuery } from 'react-query';

import {
  PaginatedQueryFilterType,
  PaginationResponse,
  TableColumn,
  TableState,
} from '@portals/types';

import { useApiQuery } from '../../hooks/query';
import {
  buildUrlFromFilters,
  fetchApiRequest,
  useRequestOptions,
} from '../../utils';
import { usePaginatedTableApiQuery } from '../../utils/paginated-table';
import { PartnerIncidentType } from './incidents.types';

const INCIDENTS_API_URL = 'ui/partner/incidents';

const incidentsQueryKeys = {
  all: [INCIDENTS_API_URL, 'incidents'],
  detail: (incidentId: string) => [...incidentsQueryKeys.all, incidentId],
};

export const usePaginatedIncidents = (
  tableState: Pick<TableState<PartnerIncidentType>, 'sortBy'>,
  columns?: Array<TableColumn>
) =>
  usePaginatedTableApiQuery<PartnerIncidentType>({
    baseUrl: INCIDENTS_API_URL,
    tableState,
    columns,
    queryOptions: { cacheTime: 0 },
  });

export function useIncidents(
  filters: Array<PaginatedQueryFilterType<PartnerIncidentType>>
) {
  const { url, options } = useRequestOptions({ url: INCIDENTS_API_URL });

  const requestUrl = buildUrlFromFilters<PartnerIncidentType>({
    url,
    filters,
    pagination: {
      page: 0,
      pageSize: 50,
    },
  });

  return useQuery<
    PaginationResponse<PartnerIncidentType>,
    { error: string },
    PartnerIncidentType[]
  >({
    queryKey: [...incidentsQueryKeys.all, requestUrl],
    queryFn: () => fetchApiRequest(requestUrl, options),
    select: (response) => response.data,
    staleTime: 0,
  });
}

export const useIncident = (incidentId: string) =>
  useApiQuery<PartnerIncidentType>(
    `${INCIDENTS_API_URL}/${incidentId}`,
    incidentsQueryKeys.detail(incidentId)
  );
